import './Rides.css';
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import PrintIcon from '@mui/icons-material/Print';
import CircularProgress from '@mui/material/CircularProgress';

import { getFirestore, query, collection, where, getDocs } from "firebase/firestore";

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import TUXTextField from '../components/TUXTextField';

import { COOKIE_ID, VIEWPORT_LIMIT, DRIVERS_TABLE, BOOKED_RIDES_TABLE } from '../libs/constants.js';

import { printDate } from '../libs/utils.js';

import RideDetails from '../components/RideDetails';

interface Driver {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
};

function Rides () {
  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
  const [loaded, setLoaded] = useState(false);
  const [rides, setRides] = useState([]);
  const [drivers, setDrivers] = useState();
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRide, setSelectedRide] = useState(null);
  const [orderBy, setOrderBy] = useState('confirm');
  const [order, setOrder] = useState('desc');
  const [cookies, setCookies] = useCookies([COOKIE_ID]);

  const {auth, setAuth} = useContext(AuthContext);
  const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

  const nav = useNavigate();
  const db = getFirestore(firebaseApp);

  const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchRides = async () => {
    getDocs(query(collection(db, BOOKED_RIDES_TABLE), where('ryder', '==', auth?.userInfo?.userId || 'unknown')))
          .then((querySnapshot)=>{
              const newData = querySnapshot.docs
                  .map((doc) => ({...doc.data(), id:doc.id }));
              setRides(newData);
              setLoaded(true);
          })

  };

  const fetchDrives = async () => {
    getDocs(collection(db, DRIVERS_TABLE))
            .then((querySnapshot)=>{
                const driversMap = new Map();
                for (const driver of querySnapshot.docs) {
                    driversMap.set(driver.id, {firstName: driver.data().firstName,
                            lastName: driver.data().lastName, email: driver.data().email,
                            phoneNumber: driver.data().phone});
                }
                setDrivers(driversMap);
            })
  };
  useEffect(() => {
        fetchDrives();
    }, []);

  useEffect(() => {
      fetchRides();
  });

  useEffect(() => {
      function handleResize() {
          setViewPortWidth(window.innerWidth);
      }
      window.addEventListener('resize', handleResize)
  });

  /*useEffect(() => {
      if ((!auth || !auth?.loggedIn || auth?.userInfo?.userId === undefined) && cookies[COOKIE_ID] === undefined) {
          nav('/signin');
      }
  });*/

  const filterRows = (rows) => {
    if (filter === null || filter.length < 1) {
        return rows;
    }
    return rows.filter((row) => {
        const confirmationCode = `${row.confirmationCode}`;
        const dateOfService = row.legs ? printDate(row.legs[0].pickUpDateTime) : 'N/A';
        const pickUpLocation = row.legs ? row.legs[0].from: 'N/A';
        const dropOffLocation = row.legs ? row.legs[0].to: 'N/A';
        return (confirmationCode.includes(filter)||
                dateOfService.includes(filter) ||
                pickUpLocation.includes(filter) ||
                dropOffLocation.includes(filter));
    });
  };

  const sortRows = (rows) => {
    return rows.sort((a, b) =>  {
        if (orderBy === 'confirm') {
            const confirmationCodeA = `${a.confirmationCode}`;
            const confirmationCodeB = `${b.confirmationCode}`;
            if (order === 'asc') {
                return confirmationCodeA.localeCompare(confirmationCodeB);
            } else {
                return confirmationCodeB.localeCompare(confirmationCodeA);
            }
        } else if (orderBy === 'date' && a.legs && b.legs) {
            const pickUpDateA = new Date(a.legs[0].pickUpDateTime).getTime();
            const pickUpDateB = new Date(b.legs[0].pickUpDateTime).getTime();
            if (order === 'asc') {
                if(pickUpDateA > pickUpDateB) {
                    return 1;
                } else if(pickUpDateA < pickUpDateB) {
                    return -1;
                } else {
                    return 0;
                }
            } else {
                if(pickUpDateB > pickUpDateA) {
                    return 1;
                } else if(pickUpDateB < pickUpDateA) {
                    return -1;
                } else {
                    return 0;
                }
            }
        } else if (orderBy === 'pickup' && a.legs && b.legs) {
            const pickupA = a.legs[0].from;
            const pickupB = b.legs[0].from;
            if (order === 'asc' ) {
                return pickupA.localeCompare(pickupB);
            } else {
                return pickupB.localeCompare(pickupA);
            }
        } else if (orderBy === 'dropoff' && a.legs && b.legs) {
              const dropOffA = a.legs[0].to;
              const dropOffB = b.legs[0].to;
              if (order === 'asc') {
                  return dropOffA.localeCompare(dropOffB);
              } else {
                  return dropOffB.localeCompare(dropOffA);
              }
        } else {
            return 0;
        }

    })
  };

  const filteredRows = useMemo(() => filterRows(sortRows(rides)), [rides, sortRows, filter, order, orderBy]);

  const visibleRows = useMemo(
      () => filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      [page, rowsPerPage, filteredRows]
    );

  const onColumnClick = (header) => {
    if (header === orderBy) {
        setOrder(order !== 'asc' ? 'asc': 'desc');
    } else {
        setOrderBy(header);
        setOrder('asc');
    }
  };

  if (visibleRows.length === 0 && !loaded) {
    return (<div className="Loading"><CircularProgress/></div>);
  }

  if (selectedRide) {
    return (
        <>
            <RideDetails ride={selectedRide} onBack={() => {setSelectedRide(null);}} />
        </>
    );
  }

  const tableFontSize = 16;
  if (viewPortWidth > VIEWPORT_LIMIT) {
      return (
        <div className="Rides">
          <div className="RidesContainer">
          <div className="RidesButton">
            <TUXTextField label="Search" type="text" value={filter} size={"small"} onChange={(event) => {setFilter(event.target.value)}}/>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{fontSize: tableFontSize}} key={'confirm'}><TableSortLabel active={orderBy === 'confirm'} onClick={() => {onColumnClick('confirm');} } direction={orderBy === 'confirm'? order : 'asc'}>Confirmation</TableSortLabel></TableCell>
                <TableCell style={{fontSize: tableFontSize}} key={'date'}><TableSortLabel active={orderBy === 'date'} onClick={() => {onColumnClick('date');} } direction={orderBy === 'date'? order : 'asc'}>Date</TableSortLabel></TableCell>
                <TableCell style={{fontSize: tableFontSize}} key={'pickup'}><TableSortLabel active={orderBy === 'pickup'} onClick={() => {onColumnClick('pickup');} } direction={orderBy === 'pickup'? order : 'asc'}>Pickup Location</TableSortLabel></TableCell>
                <TableCell style={{fontSize: tableFontSize}} key={'dropoff'}><TableSortLabel active={orderBy === 'dropoff'} onClick={() => {onColumnClick('dropoff');} } direction={orderBy === 'dropoff'? order : 'asc'}>Drop-off Location</TableSortLabel></TableCell>
                <TableCell style={{fontSize: tableFontSize}} key={'actions'}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody  >
              {
                  visibleRows.map( (ride,i) => {
                    const keyName = `${ride.type}_${i}`;
                    let driver = 'Not Assigned';
                    if (ride?.driver && drivers.get(ride?.driver)) {
                        const driverInfo = drivers.get(ride?.driver);
                        driver = `${driverInfo.firstName} ${driverInfo.lastName}, ${driverInfo.phoneNumber}`;
                    }
                    const dateOfService = ride.legs ? ride.legs[0].pickUpDateTime : 'N/A';
                    const pickUpLocation = ride.legs ? ride.legs[0].from: 'N/A';
                    const dropOffLocation = ride.legs ? ride.legs[0].to: 'N/A';
                    return (
                      <TableRow>
                        <TableCell style={{fontSize: tableFontSize}}>{ride.confirmationCode}</TableCell>
                        <TableCell style={{fontSize: tableFontSize}}>{printDate(dateOfService)}</TableCell>
                        <TableCell style={{fontSize: tableFontSize}}>{pickUpLocation}</TableCell>
                        <TableCell style={{fontSize: tableFontSize}}>{dropOffLocation}</TableCell>
                        <TableCell style={{fontSize: tableFontSize}}><Button style={{color: '#F85804'}} onClick={() => { setSelectedRide(ride) }}><PrintIcon /></Button></TableCell>
                      </TableRow>
                    );
                  })
              }
              </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>);
    } else {
        return (
        <div className="Rides">
            <div className="RidesContainer">
                  <div className="RidesButton">
                    <TextField label="Search" variant="outlined" type="text" value={filter} size={"small"} onChange={(event) => {setFilter(event.target.value)}}/>
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell key={'confirm'}><TableSortLabel active={orderBy === 'confirm'} onClick={() => {onColumnClick('confirm');} } direction={orderBy === 'confirm'? order : 'asc'}>Confirmation</TableSortLabel></TableCell>
                        <TableCell key={'date'}><TableSortLabel active={orderBy === 'date'} onClick={() => {onColumnClick('date');} } direction={orderBy === 'date'? order : 'asc'}>Date</TableSortLabel></TableCell>
                        <TableCell key={'actions'}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                          visibleRows.map( (ride,i) => {
                            const keyName = `${ride.type}_${i}`;
                            let driver = 'Not Assigned';
                            if (ride?.driver && drivers.get(ride?.driver)) {
                                const driverInfo = drivers.get(ride?.driver);
                                driver = `${driverInfo.firstName} ${driverInfo.lastName}, ${driverInfo.phoneNumber}`;
                            }
                            const dateOfService = ride.legs ? ride.legs[0].pickUpDateTime : 'N/A';
                            const pickUpLocation = ride.legs ? ride.legs[0].from: 'N/A';
                            const dropOffLocation = ride.legs ? ride.legs[0].to: 'N/A';
                            return (
                              <TableRow>
                                <TableCell>{ride.confirmationCode}</TableCell>
                                <TableCell>{printDate(dateOfService)}</TableCell>
                                <TableCell><Button style={{color: '#F85804'}} onClick={() => { setSelectedRide(ride) }} ><PrintIcon /></Button></TableCell>
                              </TableRow>
                            );
                          })
                      }
                      </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
          </div>);
    }
}

export default Rides;
