import './TUXAppBarButton.css';
import React from 'react';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

function TUXAppBarButton(props) {
    const colorButton = props?.color || 'white';
    const buttonSelected = props?.selected || false;
    let buttonClassName = colorButton;

    const buttonMap = new Map();
    buttonMap.set('white', {button: buttonSelected ? 'TUXAppBarButtonWhiteSelected' : 'TUXAppBarButtonWhite', inner: 'TUXAppBarInnerCirleWhite'});
    buttonMap.set('clear', {button: buttonSelected ? 'TUXAppBarButtonClearSelected' : 'TUXAppBarButtonClear', inner: 'TUXAppBarInnerCirleClear'});
    buttonMap.set('orange', {button: buttonSelected ? 'TUXAppBarButtonOrangeSelected' : 'TUXAppBarButtonOrange', inner: 'TUXAppBarInnerCirleOrange'});

    if (props?.bordered) {
        buttonClassName = `${buttonClassName} TUXAppBarBordered`;
    }

    return (
        <div className={buttonClassName}>
            { !(props?.disabled || false) && (<button className={buttonMap.get(colorButton).button}
                onClick={props.onClick}>
                <div className="TUXAppBarTextContainer">
                    <div className={props?.centered ? "TUXAppBarInnerText TUXAppBarCentered": "TUXAppBarInnerText"}>{props.text}</div>
                </div>
                </button>)}
            { (props?.disabled || false) && ( <button className="TUXAppBarButtonDisabled">
                    <div className="TUXAppBarTextContainer">
                        <div className={props?.centered ? "TUXAppBarInnerText TUXAppBarCentered": "TUXAppBarInnerText"}>{props.text}</div>
                    </div>
                </button>
             )}
        </div>);
}

export default TUXAppBarButton;