import React from 'react';

import TextField from '@mui/material/TextField';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

import { ThemeProvider } from '@mui/material';

import { TEXT_FIELD_THEME } from '../libs/constants.js';

function TUXTextField(props) {

    return (
        <ThemeProvider theme={TEXT_FIELD_THEME}>
            <TextField
                    variant="outlined"
                    {...props}/>
        </ThemeProvider>
    );
}

export default TUXTextField;