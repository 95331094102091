import './TUXAccordionHeader.css';
import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function TUXAccordionHeader(props) {
    const stepId = props.stepId;
    const currentStep = props.currentStep;
    const stepCompleted = props.stepCompleted;
    const isCurrentStep = (stepId === currentStep);

    return (<div className="Summary">
                <div className="SummaryHeader">{ props.sectionHeader } { (!isCurrentStep && stepCompleted) && (<span><CheckCircleIcon color="success" sx={{fontSize: 18}}/></span>) }</div>
                {(!isCurrentStep && stepCompleted) && (<div className="SummaryHeaderSubText">{props.completedText}</div>) }
                {(isCurrentStep || !stepCompleted) && (<div className="SummaryHeaderSubText">{props.text}</div>) }
            </div>);
}

export default TUXAccordionHeader;