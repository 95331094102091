import './Layout.css';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Outlet, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CircularProgress from '@mui/material/CircularProgress';

import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { VIEWPORT_LIMIT, COOKIE_ID } from '../libs/constants.js';
import { getUserInfo } from '../libs/utils.js';

import { LogoTextLink } from '../components/Logo';
import TUXButton from '../components/TUXButton';
import TUXAppBarButton from '../components/TUXAppBarButton';
import TUXAppBarLink from '../components/TUXAppBarLink';
import { ContactUsDark }  from '../components/ContactUs';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

function Layout () {
  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
  const nav =  useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [cookies, setCookies] = useCookies([COOKIE_ID]);
  const {auth, setAuth} = useContext(AuthContext);
  const {firebaseApp} = useContext(FirebaseContext);
  const open = Boolean(anchorElNav);

  const db = getFirestore(firebaseApp);
  const authFromApp = getAuth(firebaseApp);

  onAuthStateChanged(authFromApp, (user) => {
    if (user && !auth?.loggedIn) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      getUserInfo(db, uid).then((userInfo) => {
          if (userInfo != null) {
              setAuth({loggedIn: true, user: {}, userInfo: userInfo});
          }
      });
    } else if (!auth?.loggedIn)  {
        if (location.pathname !== '/signin' &&
            location.pathname !== '/signup' &&
            location.pathname !== '/resetpassword' &&
            !location.pathname.includes('schedule') &&
            location.pathname !== '/' ) {
            nav('/signin');
        }
    }
  });

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const clickHome = () => {
    nav('/');
    handleCloseNavMenu();
  };

  const clickSchedule = () => {
      nav('/schedule');
      handleCloseNavMenu();
    };

  const clickRides = () => {
    nav('/rides');
    handleCloseNavMenu();
  };

  const clickProfile = () => {
    nav('/profile');
    handleCloseNavMenu();
  };

  const clickSignIn = () => {
    nav('/signin');
    handleCloseNavMenu();
  }

  useEffect(() => {
    function handleResize() {
        setViewPortWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize)
  });

  const isSmallViewPort = (viewPortWidth < VIEWPORT_LIMIT);
  const isLoggedIn = (auth?.loggedIn && auth?.userInfo?.userId !== undefined);
  return (
    <>
      { location.pathname !== '/' && location.pathname !== '/signin' && location.pathname !== '/signup'&& (
        <>
        { !isSmallViewPort && (
            <div className="TUXAppBar">
                    <div className="TUXLogoBox">
                        <LogoTextLink />
                     </div>
                    {!isLoggedIn && (
                        <div className="TUXAppBarLoggedIn">
                            <ContactUsDark/>
                            <TUXButton color="white" text="Login In" onClick={clickSignIn} />
                        </div>
                    )}
                    {isLoggedIn && (
                      <div className="TUXAppBarUser">
                        <ContactUsDark/>
                        <TUXAppBarLink color="white" text="Schedule Ride" to={"/schedule"} reload={location.pathname.includes("/schedule")} />
                        <TUXAppBarLink color="white" text="My Trips" to={"/rides"} reload={location.pathname === "/rides"} />
                        <TUXAppBarLink color="white" text="My Profile" to={"/profile"}  reload={location.pathname === "/profile"}/>
                      </div>
                    )}
                </div> )}
        { isSmallViewPort && (
            <Stack>
             <div className="LayoutAppBarSmallViewPort">
                 <Stack direction="row" spacing={2} alignItems={"center"}>
                      <Button
                        id="menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleOpenNavMenu}
                        style={{color: 'black'}}
                      >
                        <MenuIcon sx={{fontSize: 40}}/>
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElNav}
                        open={open}
                        onClose={handleCloseNavMenu}
                        MenuListProps={{
                          'aria-labelledby': 'menu-button',
                        }}
                      >
                      {isLoggedIn && (
                        <>
                        <MenuItem onClick={clickHome}>Home</MenuItem>
                        <MenuItem onClick={clickSchedule}>Schedule Ride</MenuItem>
                        <MenuItem onClick={clickRides}>My Trips</MenuItem>
                        <MenuItem onClick={clickProfile}>My Profile</MenuItem>
                        </>)}
                      {!isLoggedIn && (
                          <>
                          <MenuItem onClick={clickSignIn}>Login</MenuItem>
                          </>)}
                      </Menu>
                      <div className="TUXRydeText">TUXRydes</div>
                </Stack>
            </div>
            <div className="ContactUsBottom">
                <ContactUsDark/>
            </div>
        </Stack>)}

        </> )}
        <Outlet />
    </>
  )
}

export default Layout;
