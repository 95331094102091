import './RideMap.css';
import React, { useState, useEffect } from 'react';

import {Map, Marker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';

import { VIEWPORT_LIMIT } from '../libs/constants.js';

const placeService = { current: null };

function RideMap(props) {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [fromLatLng, setFromLatLng] = useState(null);
    const [toLatLng, setToLatLng] = useState(null);
    const [centerLatLng, setCenterLatLng] = useState(null);

    const map = useMap();
    const placesLib = useMapsLibrary('places');

    useEffect(() => {
        if (!placesLib || !map) return undefined;

        if (!placeService.current) {
            placeService.current = new placesLib.PlacesService(map);
        }

        if (!placeService.current) {
          return undefined;
        }
        placeService.current.getDetails({placeId: props.from }, (result, status) => {
            if (status === placesLib.PlacesServiceStatus.OK) {
                setFromLatLng(result.geometry.location);
            }
        });

        placeService.current.getDetails({placeId: props.to }, (result, status) => {
            if (status === placesLib.PlacesServiceStatus.OK) {
                setToLatLng(result.geometry.location);
            }
        });
    },[map, placesLib]);

   useEffect(() => {
        if (!fromLatLng || !toLatLng) return undefined;

        const lat = (fromLatLng.lat() + toLatLng.lat()) / 2;
        const lng = (fromLatLng.lng() + toLatLng.lng()) / 2;
        setCenterLatLng({lat: lat, lng: lng});
    }, [fromLatLng, toLatLng]);

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    const getZoom = () => {
        if (!props.duration) {
            return 10;
        }
        const zoomScale = Math.round(props.duration / 900);
        const zoom = 13 - zoomScale;
        if (zoom > 8) {
            return zoom;
        }
        return 8;
    };

    return (
        <div className={viewPortWidth > VIEWPORT_LIMIT? 'Map':'SmallMap'}>
            <Map zoom={getZoom()} center={centerLatLng}>
              <Marker position={fromLatLng} title={'Pick-up'} />
              <Marker position={toLatLng}  title={'Drop-off'}/>
            </Map>
        </div>
    );
}

export default RideMap;