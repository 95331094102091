import './TUXActionButton.css';
import React from 'react';

function TUXActionButton(props) {
    const disabled = props?.disabled || false;
    const ghosted = props?.ghosted || false;
    const flex = props?.flex || false;

    let className = "TUXActionButton";
    if (flex) {
        className = `${className}Flex`;
    }
    if (disabled) {
        className = `${className}Disabled`;
    }
    if (ghosted) {
        className = `${className}Ghost`;
    }
    return (
        <div className={className}
              onClick={!disabled ? props.onClick : null}>
            <button>{props.children}</button>
        </div>
    );


}

export default TUXActionButton;