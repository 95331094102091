import React, {useState} from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider } from '@mui/material/styles';

import TextField from '@mui/material/TextField';

import TUXActionButton from '../components/TUXActionButton';

import { TEXT_FIELD_THEME } from '../libs/constants.js';

function Other(props) {
    const [where, setWhere] = useState(null);
    const [email, setEmail] = useState(null);
    const [newsletter, setNewsletter] = useState(true);
    return (
    <div className="Other">
        <ThemeProvider theme={TEXT_FIELD_THEME}>
            <Stack spacing={2} direction="column">
                <TextField minRows="10" value={where} onChange={(event) => { setWhere(event.target.value); }} aria-label="where do you want to go" label="Where Do You Want to Go?"  multiline/>
                <TextField label="Email" type="email" value={email} onChange={(event) => { setEmail(event.target.value); }}/>
                <div>
                <Checkbox checked={newsletter} onChange={(event) => { setNewsletter(event.target.checked); }}/> Send me updates about new service areas.
                </div>
                <div class="OtherButtonBar">
                    <TUXActionButton flex={true} onClick={() => props.sendRequest({email: email, where: where, newsletter: newsletter})} disabled={(where === null || email === null || where === "" || email === "")}>Send Request</TUXActionButton>
                </div>
            </Stack>
        </ThemeProvider>
    </div>);
}

export default Other;